import Auth from '@aws-amplify/auth'; 
import { CognitoUserSession } from 'amazon-cognito-identity-js';  
import { isEmpty } from 'lodash';
import store from '../store/';

export interface AuthUrls {
  core: string;
  user: string;
  vital: string;
}

class AuthService {

  static isAuthorized(routeRoles: string[]): boolean {

    try {

      const reduxStore: any = store.getState();
      const auth = (!isEmpty(reduxStore.auth.auth.user) ? reduxStore.auth.auth.user : null);

      console.log('auth', auth);

      if (!auth) {
        return false;
      }

      // const cognitoUser = await Auth.currentAuthenticatedUser();

      if (AuthService.checkUserRoles(auth.role, routeRoles)) {
        return true;
      }
      return false;

    } catch (error) {
      return false;
    }
  }

  static getUserId(): string {
    const reduxStore: any = store.getState();
    const auth = (!isEmpty(reduxStore.auth.auth.user) ? reduxStore.auth.auth.user : null);
    if (!auth) {
      return '';
    } 
    return auth.id;
  }

  static checkUserRoles(userRoles: string, routeRoles: string[]): boolean {
    let hasRole = false;
    routeRoles.forEach((role: string) => {
      if (userRoles.indexOf(role) !== -1) {
        hasRole = true;
      }
    });
    return hasRole;
  }


  static refreshToken(): Promise<CognitoUserSession> {
    
    return new Promise( async (resolve, reject) => {

      try {
        
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        const refreshToken = currentSession.getRefreshToken();
        cognitoUser.refreshSession(refreshToken, (err: any, session: CognitoUserSession) => {
          
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }

        });
        
      } catch (e) {
        console.log('Unable to refresh Token', e);
      }
    
    });

  }

  static getApiUrls(): AuthUrls {
    return {
        core: 'https://coreservice.nowperformancedemo.com',
        user: 'https://userservice.nowperformancedemo.com',
        // user: 'http://localhost:4001',
        vital: 'https://vitalservice.nowperformancedemo.com',
        // vital: 'http://localhost:4002'
    }
  }
}

export default AuthService;
