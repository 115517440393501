import React from 'react'

import AppContainer from '../../containers/App';

const logo = process.env.PUBLIC_URL + '/logo.png';

const NotFound: React.FC<{}> = () => {
  return (
    <AppContainer showOption={false} private={false} maxWidth="xl">
      <div style={styles.notFound}>Page Not Found</div>
      <div style={styles.imageContainer}>
        <img src={logo} style={styles.image} alt="nowperformance logo" />
      </div>
    </AppContainer>
  )
}
export default NotFound;

const styles: any = {
  notFound: {
    fontSize: 48,
    textAlign: 'center',
    padding: 40
  },
  imageContainer: {
    paddingTop: 40,
  },
  image: {
    width: 200,
    display: 'flex',
    margin: '0px auto'
  }
}