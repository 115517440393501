import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';

// material
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
// components
import Loading from '../../components/site/Loading';
// containers
import AppContainer from '../../containers/App';
// entities
import { Auth } from '../../entities/auth';
import { Vital, VitalRow, VitalData } from '../../entities/vital';
// site
import { openSnackbar, closeSnackbar } from '../../store/action-creators';
// services
import AuthService from '../../services/auth';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {

        },
    })
);

interface HeaderProps {
    history: any;
    auth: Auth;
    openSnackbar(message: string): void;
    closeSnackbar(): void;
}

const Dashboard: React.FC<HeaderProps> = (props) => {

    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [vitals, setVitals] = useState<Vital[]>([]);
    const [vitalsCsv, setVitalsCsv] = useState<VitalData[]>([]);

    const fetchVitals = async () => {
        try {
            setLoading(true);
            const vitalRows: VitalData[] = [];
            const vitalsRes = await axios.get(`${AuthService.getApiUrls().vital}/vitals?type=accelerometer`);
            const results = vitalsRes.data.results;
            results.forEach((vital: Vital) => {
                vital.data.forEach((data: any) => {
                    vitalRows.push({
                        x: data.x,
                        y: data.y,
                        z: data.z
                    })
                });
            });

            setVitalsCsv(vitalRows);
            setVitals(vitalsRes.data.results);
            setLoading(false);
            console.log('vitalsRes', vitalsRes);
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        // load
        fetchVitals();
    }, []);

    const data = [

        {
            "x": 34,
            "y": 198,
            "z": 50
        },
        {
            "x": 23,
            "y": 204,
            "z": 53
        },
        {
            "x": 43,
            "y": 262,
            "z": 55
        },
        {
            "x": 23,
            "y": 71,
            "z": 59
        },
        {
            "x": 56,
            "y": 12,
            "z": 60
        },
        {
            "x": 23,
            "y": 282,
            "z": 78
        },
        {
            "x": 67,
            "y": 154,
            "z": 23
        },
        {
            "x": 78,
            "y": 5,
            "z": 34
        },
        {
            "x": 23,
            "y": 134,
            "z": 45
        },
        {
            "x": 12,
            "y": 42,
            "z": 56
        },
        {
            "x": 456,
            "y": 261,
            "z": 50
        },
        {
            "x": 56,
            "y": 119,
            "z": 12
        }
    ];

    return (
        <AppContainer showOption={false} private={false} maxWidth="lg">
            <Loading open={loading} />
            <div className={classes.container}>dashboard</div>
            <div style={{ height: 800 }}>
                {/* <ResponsiveBar
                    data={vitalsCsv}
                    keys={['x', 'y', 'z']}
                    indexBy="country"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    colors={{ scheme: 'nivo' }}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'fries'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'sandwich'
                            },
                            id: 'lines'
                        }
                    ]}
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'country',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'food',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                /> */}
            </div>
            {/* <div style={{ height: 800 }}>
                <ResponsiveLine
                    data={data}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'transportation',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'count',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div> */}
        </AppContainer>
    );
};

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth.auth
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        openSnackbar,
        closeSnackbar
    }, dispatch);
};

export default withRouter<any, any>(
    connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
