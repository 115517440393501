import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AppContainer from '../../containers/App';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteAuth } from '../../store/action-creators';

const styles: any = {
  container: {
    maxWidth: 800,
    margin: '0 auto',
    marginTop: 60
  },
  containerLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    paddingLeft: 20,
    color: '#fff',
    fontSize: 18
  }
}

interface LogoutProps {
  history: any;
  deleteAuth(): void;
}

class Logout extends Component<LogoutProps, {}> {
  
  componentDidMount = () => {
    this.props.deleteAuth()
    this.props.history.push('/');
  }

  render() {
    return ( 
      <AppContainer private={false}>
        <div style={styles.container}>
          <div style={styles.containerLoading}>
            <div style={styles.spinner}><CircularProgress /></div>
            <div style={styles.text}>Logging out....</div>
          </div>
        </div>
      </AppContainer>
    )
  }

}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ deleteAuth }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter<any, any>(Logout));
