import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
// app
import { addAuth } from '../../store/action-creators';
import { colors } from '../../config/styles';
// containers
import AppContainer from '../../containers/App';
// models
import { Auth as AuthEntity } from '../../entities/auth';
import { Message, MessageTypes } from '../../entities/site';
// components
import ActionButton from '../../components/buttons/ActionButton';
import RowContainer from '../../containers/Row';
import TextInput from '../../components/inputs/TextInput';
// services
// import UtilsService from '../../services/utils';
import AuthService from '../../services/auth';

const lowerRegex = new RegExp("^(?=.*[a-z])");
const upperRegex = new RegExp("^(?=.*[A-Z])");
const numberRegex = new RegExp("^(?=.*[0-9])");
const lengthRegex = new RegExp("^(?=.{6,})");

const styles: any = {
    container: {
        height: "100%",
        minHeight: "100%",
        maxWidth: 600,
        margin: '0px auto',
        padding: 40,
        backgroundColor: '#FFF'
    },
    link: {
        color: colors.blue,
        cursor: 'pointer'
    },
    footer: {
        textAlign: 'center',
        fontSize: 12
    }
}

interface CreateUserPageProps {
    history: any;
    auth: AuthEntity;
    addAuth(auth: AuthEntity): void;
}

interface CreateUserPageState {
    name: string;
    phone: string;
    email: string;
    password: string;
    passwordConfirm: string;
    message: Message;
    disabled: boolean;
}

class CreateUser extends Component<CreateUserPageProps, CreateUserPageState> {

    constructor(props: CreateUserPageProps) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            password: '',
            passwordConfirm: '',
            message: {
                message: '',
                messageType: MessageTypes.notification
            },
            disabled: false
        }
    }

    componentDidMount = () => {
        // mounted
    }

    onNameChange = (event: any) => {
        this.setState({ name: event.target.value });
    }

    onEmailChange = (event: any) => {
        this.setState({ email: event.target.value });
    }

    onPhoneChange = (event: any) => {
        this.setState({ phone: event.target.value });
    }

    onPasswordChange = (event: any) => {
        this.setState({ password: event.target.value });
    }

    onPasswordConfirmChange = (event: any) => {
        this.setState({ passwordConfirm: event.target.value });
    }

    handleSignup = async () => {
        // sign up
        try {

            if (!this.checkForm()) {
                return;
            }

            this.setState({
                disabled: true
            });

            const phone = this.state.phone || '';
            const data: any = {
                username: this.state.email,
                password: this.state.password,
                attributes: {
                    name: this.state.name
                },
                clientMetadata: {
                    autoConfirmUser: "true"
                }
            };

            if (phone) {
                data.attributes.phone_number = `+1${this.state.phone}`;
            }

            const signupRes: any = await Auth.signUp(data);

            console.log('signupRes', signupRes);

            const userData = {
                id: signupRes.userSub,
                email: this.state.email,
                name: this.state.name,
                phone
            };
            await axios.post(`${AuthService.getApiUrls().user}/user`, userData);

            this.setState({
                disabled: false
            });

        } catch (error) {
            this.setState({ disabled: false });
            this.setState({ message: { message: error, messageType: MessageTypes.error } });
        }
    }

    checkForm = () => {
        let nameError = '';
        let pError = '';
        let emailError = '';
        if (!this.state.email) {
            emailError = 'Email required';
        }
        if (!this.state.name) {
            nameError = 'Name required';
        }
        if (!this.state.password) {
            pError = 'Password required';
        }
        if (this.state.password !== this.state.passwordConfirm) {
            pError = 'Passwords do not match';
        }
        if (
            !lowerRegex.test(this.state.password) ||
            !upperRegex.test(this.state.password) ||
            !numberRegex.test(this.state.password) ||
            !lengthRegex.test(this.state.password)
        ) {
            pError = 'Password must be at least 6 chars, 1 upper and 1 number';
        }
        // this.props.setMessage({message: 'Error logging in', messageType: MessageTypes.error});
        this.setState({ message: { message: pError, messageType: MessageTypes.error } });
        if (!emailError && !nameError && !pError) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <AppContainer showOption={false} private={false} openSignup={false} maxWidth="lg">
                <div style={styles.container}>
                    <RowContainer>
                        <TextInput
                            title="Name"
                            type="string"
                            placeholder=""
                            result={this.state.name}
                            onChange={this.onNameChange}
                        />
                    </RowContainer>
                    <RowContainer>
                        <TextInput
                            title="Phone - (Optional)"
                            type="phone"
                            placeholder=""
                            result={this.state.phone}
                            onChange={this.onPhoneChange}
                        />
                    </RowContainer>
                    <RowContainer>
                        <TextInput
                            title="Email"
                            type="string"
                            placeholder=""
                            result={this.state.email}
                            onChange={this.onEmailChange}
                        />
                    </RowContainer>
                    <RowContainer>
                        <TextInput
                            title="Password"
                            type="password"
                            placeholder=""
                            result={this.state.password}
                            onChange={this.onPasswordChange}
                        />
                    </RowContainer>
                    <RowContainer>
                        <TextInput
                            title="Confirm Password"
                            type="password"
                            placeholder=""
                            result={this.state.passwordConfirm}
                            onChange={this.onPasswordConfirmChange}
                        />
                    </RowContainer>
                    <RowContainer>
                        <ActionButton name="Create User" onClick={this.handleSignup} fullWidth size="sm" disabled={this.state.disabled} />
                    </RowContainer>
                </div>
            </AppContainer>
        )
    }

}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth.auth
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ addAuth }, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter<any, any>(CreateUser));
