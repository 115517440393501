import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';

// app
import { colors } from '../../../config/styles';


const styles: any = {
  container: {
    backgroundColor: '#F1F1F1',
    borderBottomColor: '#E1E1E1',
    borderBottomWidth: 1
  },
  left: {
    fontSize: 14,
    fontWeight: '600',
    padding: 10,
    textAlign: 'left',
    color: colors.darkGray
  },
  right: {
    paddingRight: 10,
    paddingTop: 10,
    textAlign: 'right'
  },
  icon: {
    fontSize: 18,
    color: colors.darkGray
  }
}

interface LoginProps {
  title: string;
  onClose(): void;
}

class AuthHeader extends Component<LoginProps, {}> {
  
  constructor(props: LoginProps) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = () => {
    // mounted
  }

  render() {
    return ( 
      <Grid container style={styles.container}>
        <Grid item xs={6}>
          <div style={styles.left}>{this.props.title}</div>
        </Grid>
        <Grid item xs={6}>
          <div style={styles.right}><Close onClick={this.props.onClose} style={styles.icon} /></div>
        </Grid>
      </Grid>
    )
  }

}

export default AuthHeader;
