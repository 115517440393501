
import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    color: '#FFF',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: '#00ABCA',
    border: '1px solid #00c1e4',
    fontFamily:'Asap',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#0095b1'
    }
  },
  secondary: {
    color: '#A5A5A5',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: '#F8F8F8'
  },
  link: {
    color: '#00adcc',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

const useStylesSmall = makeStyles({
  root: {
    color: '#FFF',
    textTransform: 'capitalize',
    backgroundColor: '#00ABCA',
    border: '1px solid #BDE8F2',
    fontFamily:'Asap',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#0095b1'
    }
  },
  secondary: {
    color: '#A5A5A5',
    textTransform: 'capitalize',
    backgroundColor: '#00c1e4'
  }
});

interface ActionButtonProps {
  name: string;
  loading?: boolean;
  size?: string;
  type?: string;
  fullWidth?: boolean;
  textOnly?: boolean;
  disabled?: boolean;
  onClick(): void;
}

// const ActionButton = (props: ActionButtonProps) => (
//   <div style={(props.size && props.size === "small" ? styles.containerSmall : styles.container)} onClick={props.onClick}>
//     { (props.loading !== undefined && props.loading) &&
//       <CircularProgress size={15} style={{color: '#FFF', marginLeft: '47%'}} />
//     }
//     { !props.loading &&
//       <div style={styles.button}>{props.name}</div>
//     }
//   </div>
// );


const ActionButton = (props: ActionButtonProps) => {
  const classes = useStyles();
  const classesSmall = useStylesSmall();

  let className: any = classes.root;
  if (props.type && props.type === 'secondary') {
    className = classes.secondary;
  }

  if (props.size && props.size === 'sm') {
    className = classesSmall.root;
    if (props.type && props.type === 'secondary') {
      className = classesSmall.secondary;
    }
  }

  return (
    <div>
      { props.textOnly &&
        <div onClick={props.onClick} className={classes.link}>{props.name}</div>
      }
      { !props.textOnly &&
        <Button 
          variant="contained" 
          color="primary" 
          className={className} 
          fullWidth={(props.fullWidth !== undefined ? props.fullWidth : false)} 
          onClick={props.onClick}
          disabled={(props.disabled !== undefined ? props.disabled : false)} 
        >
          {props.name}
        </Button>
      }
    </div>
  )
};

export default ActionButton;
