import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// material
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
// entities
import { Auth } from '../../entities/auth';
// site
import { openSnackbar, closeSnackbar } from '../../store/action-creators';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'fixed',
            bottom: 0,
            width: '100%',
            padding: 15
        },
        text: {
            textAlign: 'center'
        }
    })
);

interface HeaderProps {
    history: any;
    auth: Auth;
    openSnackbar(message: string): void;
    closeSnackbar(): void;
}

const Footer: React.FC<HeaderProps> = (props) => {

    const classes = useStyles();
    // const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // load
    });

    return (
        <div className={classes.container}>
          <div className={classes.text}>
            Nowperformance Demo Site | Copyright © 2021
          </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth.auth
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        openSnackbar,
        closeSnackbar
    }, dispatch);
};

export default withRouter<any, any>(
    connect(mapStateToProps, mapDispatchToProps)(Footer)
);
