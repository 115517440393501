import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { closeSnackbar, openSnackbar } from '../store/action-creators';
import { withRouter } from 'react-router-dom';
// material
import Container from '@material-ui/core/Container';
import Header from '../components/site/Header';
import Footer from '../components/site/Footer';
// import Footer from '../components/footer/Footer';
import { Auth } from '../entities/auth';

const styles: any = {
  container: {
    width: '100%',
    minHeight: '100%',
  },
  bodyContainer: {
    // backgroundSize: 'cover',
    // backgroundAttachment: 'fixed',
    // backgroundPosition: 'center',
    // backgroundImage: `url(${background})`,
    minHeight: 600,
    marginBottom: 40
  },
  copyrightText: {
    color: '#7e8ea9',
    marginTop: 12
  },
  copyRightFooter: {
    height: 100,
    background: '#1e2b3a',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}

interface AppContainerProps {
  history: any;
  children: any;
  auth: Auth;
  openSignup?: boolean;
  loading?: boolean;
  showOption?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  onRefresh?(): void;
  openSnackbar(msg: string): void;
  closeSnackbar(event: any): void;
}

class AppContainer extends Component<AppContainerProps, {}> {

  componentDidMount = () => {

  }

  onNavigate = (route: string) => {
    this.props.history.push(route);
  }

  render() {
    const maxWidth = this.props.maxWidth || false;
    return (
      <div style={styles.container}>
        <Header showLogo />
        <Container maxWidth={maxWidth}>
          <div style={styles.bodyContainer}>
            {this.props.children}
          </div>
        </Container>
        <Footer />
        {/* <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={(this.props.site.snackbar && this.props.site.snackbarMsg ? true : false)}
          onClose={this.props.closeSnackbar}
          autoHideDuration={2000}
          classes={{
            root: 'message',
          }}
          ContentProps={{
            className: 'message-content'
          }}
          message={<span id="message-id">{this.props.site.snackbarMsg}</span>}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    site: state.site.site
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ closeSnackbar, openSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(AppContainer));