enum actions {
  ADD_AUTH = "ADD_AUTH",
  CLEAR_AUTH = "CLEAR_AUTH",
  DELETE_AUTH = "DELETE_AUTH",
  ADD_SESSION = "ADD_SESSION",
  UPDATE_SESSION = "UPDATE_SESSION",
  OPEN_SNACKBAR = "OPEN_SNACKBAR",
  CLOSE_SNACKBAR = "CLOSE_SNACKBAR",
  SET_SIGNUP = "SET_SIGNUP",
  CLEAR_SIGNUP = "CLEAR_SIGNUP",
  SET_MESSAGE = "SET_MESSAGE",
  CLEAR_MESSAGE = "CLEAR_MESSAGE"
}
export default actions;
