// base
import HomePage from "../pages/home/Home";
// import ContactPage from "../pages/contact/Contact";
import DashboardPage from '../pages/dashboard/Dashboard';
import ReportsPage from '../pages/reports/Reports';
import CreateUser from '../pages/auth/CreateUser';

// public
import LoginPage from "../pages/auth/Login";
import LogoutPage from "../pages/auth/Logout";


const routes = {
  public: [
    {
      name: "Logout",
      path: "/auth/logout",
      exact: true,
      component: LogoutPage,
    },
    {
      name: "Login",
      path: "/auth/login",
      exact: true,
      component: LoginPage,
    },
    {
      name: "Home",
      path: "/",
      exact: true,
      component: HomePage,
    },
  ],
  private: [
    {
      name: "Dashboard",
      path: "/dashboard",
      exact: true,
      component: DashboardPage,
      roles: ["admin", "user"],
    },
    {
      name: "Reports",
      path: "/reports",
      exact: true,
      component: ReportsPage,
      roles: ["admin", "report"],
    },
    {
      name: "UserCreate",
      path: "/user/create",
      exact: true,
      component: CreateUser,
      roles: ["admin"],
    }
  ],
};

export default routes;
