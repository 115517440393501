import React from 'react';
// material
// import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent } from '@material-ui/core';
// app
import { Message, MessageTypes } from '../../../entities/site';
// components
import AuthHeader from '../components/AuthHeader';
import AuthMessage from '../components/AuthMessage';

// const useStyles = makeStyles(theme => ({
//   container: {
//     margin: '0 auto',
//     minWidth: 400,
//     maxWidth: 800,
//     paddingLeft: 10,
//     paddingRight: 10,
//     paddingBottom: 10,
//     [theme.breakpoints.down('sm')]: {
//       minWidth: 275,
//     },
//   },
// }));

interface AuthContainerProps {
  title: string;
  open: boolean;
  message?: Message;
  onClose(data?: any): void;
}

const AuthContainer: React.FC<AuthContainerProps> = (props) => {

  // const classes = useStyles({});
  const message = props.message || { message: '', messageType: MessageTypes.notification };
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <AuthHeader title={props.title} onClose={() => props.onClose()} />
      { message.message !== '' && (
        <AuthMessage message={message} />
      )}
      <DialogContent style={{ minWidth: 400, padding: '15px 25px 25px 25px' }}>
        {props.children}
      </DialogContent>
    </Dialog>
  )

}
export default AuthContainer;