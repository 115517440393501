import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
// app
import { addAuth } from '../../store/action-creators';
import { colors } from '../../config/styles';
// containers
import AppContainer from '../../containers/App';
// models
import { Auth as AuthEntity } from '../../entities/auth';
// components
import ActionButton from '../../components/buttons/ActionButton';
import RowContainer from '../../containers/Row';
import TextInput from '../../components/inputs/TextInput';
import Signup from './components/Signup';
import ConfirmCode from './components/ConfirmCode';
// services
// import UtilsService from '../../services/utils';
import AuthService from '../../services/auth';

const styles: any = {
  container: {
    height: "100%",
    minHeight: "100%",
    maxWidth: 600,
    margin: '0px auto',
    padding: 40,
    backgroundColor: '#FFF'
  },
  link: {
    color: colors.blue,
    cursor: 'pointer'
  },
  footer: {
    textAlign: 'center',
    fontSize: 12
  }
}

interface LoginPageProps {
  history: any;
  auth: AuthEntity;
  addAuth(auth: AuthEntity): void;
}

interface LoginPageState {
  loading: boolean;
  disabled: boolean;
  email: string;
  password: string;
  openSignup: boolean;
  showConfirmCode: boolean;
}

class LoginPage extends Component<LoginPageProps, LoginPageState> {

  constructor(props: LoginPageProps) {
    super(props);
    this.state = {
      loading: false,
      disabled: false,
      email: '',
      password: '',
      openSignup: false,
      showConfirmCode: false
    }
  }

  componentDidMount = () => {
    // home
  }

  handleLogin = async () => {
    // login
    try {
      // this.props.clearMessage();
      this.setState({ loading: true, disabled: true });
      const user = await Auth.signIn(this.state.email.trim(), this.state.password.trim());
      console.log('user', user);
      const authSession: any = await Auth.currentSession();
      console.log('authSession', authSession);

      // get db user
      const userRes = await axios.get(`${AuthService.getApiUrls().user}/user/${user.username}`);

      const auth: AuthEntity = {
        id: user.username,
        user: userRes.data,
        token: authSession.accessToken.jwtToken,
        payload: authSession.accessToken.payload
      }

      this.props.addAuth(auth);
      this.handleRoute('/reports');

    } catch (error) {
      this.setState({ loading: false, disabled: false });
      // await UtilsService.logError(AuthService.getApiUrls().core, 'Login', 'handleLogin', error);
    }
  }

  handleRoute = (route: string) => {
    this.props.history.push(route);
  }

  onEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  }

  onPasswordChange = (event: any) => {
    this.setState({ password: event.target.value });
  }

  handleSignup = () => {
    this.setState({ openSignup: true });
  }

  handleSignupClose = (action: string) => {
    console.log('action', action);
    this.setState({ openSignup: false });
    if (action === 'confirm') {
      this.setState({ showConfirmCode: true });
    }

  }

  handleConfirmClose = (route?: string) => {
    this.setState({ showConfirmCode: false });
    if (route) {
      this.props.history.push(route);
    }
  }

  render() {
    return (
      <AppContainer showOption={false} private={false} openSignup={this.state.openSignup} maxWidth="lg">
        <div style={styles.container}>
          <RowContainer>
            <TextInput
              type="string"
              placeholder="Email"
              title="Email"
              result={this.state.email}
              onChange={this.onEmailChange}
            />
          </RowContainer>
          <RowContainer>
            <TextInput
              type="password"
              placeholder="Password"
              title="Password"
              result={this.state.password}
              onChange={this.onPasswordChange}
            />
          </RowContainer>
          <RowContainer>
            <ActionButton name="Login" onClick={this.handleLogin} loading={this.state.loading} size="sm" fullWidth disabled={this.state.disabled} />
          </RowContainer>
          <RowContainer>
            <div style={styles.footer}>Not a member? <span style={styles.link} onClick={this.handleSignup}>Sign Up</span></div>
          </RowContainer>
        </div>
        <Signup open={this.state.openSignup} onClose={this.handleSignupClose} />
        <ConfirmCode open={this.state.showConfirmCode} onClose={this.handleConfirmClose} />
      </AppContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ addAuth }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(LoginPage));
