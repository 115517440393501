import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from 'lodash';
import Auth from '@aws-amplify/auth';

// material
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, Container } from "@material-ui/core";
// entities
import { Auth as AuthEntity } from '../../entities/auth';
// site
import { openSnackbar, closeSnackbar, deleteAuth, clearSignup } from '../../store/action-creators';

const logo = process.env.PUBLIC_URL + '/np-logo-banner-small.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 15,
            paddingMottom: 15,
            backgrounfColor: '#FFF'
        },
        logo: {
            height: 50,
            cursor: 'pointer'
        },
        item: {
            paddingRight: 10,
            color: 'rgb(0, 179, 254)',
            cursor: 'pointer'
        },
        itemEnd: {
            color: 'rgb(0, 179, 254)',
            cursor: 'pointer'
        }
    })
);

interface HeaderProps {
    history: any;
    auth: AuthEntity;
    showLogo: boolean;
    openSnackbar(message: string): void;
    closeSnackbar(): void;
    clearSignup(): void;
    deleteAuth(): void;
}

const Header: React.FC<HeaderProps> = (props) => {

    const classes = useStyles();

    const handleClick = (route: string) => {
        props.history.push(route);
    }

    const handleLogout = async () => {
        props.clearSignup();
        props.deleteAuth();
        await Auth.signOut();
        props.history.push('/');
    }

    useEffect(() => {
        // load
    });

    return (
        <Container maxWidth="lg">
            <div className={classes.container}>
                <div onClick={() => handleClick('/')}>
                    {props.showLogo &&
                        <img src={logo} className={classes.logo} alt="nowperformance" />
                    }
                </div>
                <div style={{ display: 'flex' }}>
                    {!isEmpty(props.auth) &&
                        <div style={{ display: 'flex' }}>
                            {/* <div className={classes.item} onClick={() => handleClick('/dashboard')}>Dashboard</div> */}
                            <div className={classes.item} onClick={() => handleClick('/reports')}>Reports</div>
                            <div className={classes.item} onClick={handleLogout}>Logout</div>
                        </div>
                    }
                    {isEmpty(props.auth) &&
                        <div className={classes.itemEnd} onClick={() => handleClick('/auth/login')}>Login</div>
                    }
                </div>
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth.auth
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        openSnackbar,
        closeSnackbar,
        deleteAuth,
        clearSignup
    }, dispatch);
};

export default withRouter<any, any>(
    connect(mapStateToProps, mapDispatchToProps)(Header)
);
