import React from 'react';
import { Modal, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(238, 238, 238, 0.2)'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      border: 'none'
    },
    progress: {
      margin: 20
    },
    title: {
      fontSize: 18,
      textAlign: 'center'
    }
  }),
);

interface LoadingProps {
  open: boolean;
  text?: string;
}
  
const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {

  const classes = useStyles({});

  const loadingText = props.text !== undefined ? props.text : 'loading...';

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
      className={classes.modal}
      hideBackdrop
      disableAutoFocus
    >
      <div className={classes.paper}>
        <CircularProgress className={classes.progress} />
        <div className={classes.title}>{loadingText}</div>
      </div>
  </Modal>
  )  
}
export default Loading;