import { combineReducers } from "redux";
import actions from "./actions";

export const auth = (state = { auth: {} }, action: any) => {
    switch (action.type) {
        case actions.SET_SIGNUP:
            state = Object.assign({}, state, {
                signup: action.payload,
            });
            break;
        case actions.CLEAR_SIGNUP:
            state = Object.assign({}, state, {
                signup: action.payload,
            });
            break;
        case actions.ADD_AUTH:
            state = Object.assign({}, state, {
                auth: action.payload,
            });
            break;
        case actions.DELETE_AUTH:
            state = Object.assign({}, state, {
                auth: action.payload,
            });
            break;
    }
    return state;
};

export const site = (state = { site: {} }, action: any) => {
    switch (action.type) {
        case actions.OPEN_SNACKBAR:
            state = Object.assign({}, state, {
                site: {
                    snackbar: true,
                    snackbarMsg: action.payload,
                },
            });
            break;
        case actions.CLOSE_SNACKBAR:
            state = Object.assign({}, state, {
                site: {
                    snackbar: false,
                    snackbarMsg: "",
                },
            });
            break;
    };
    return state;
}

export default combineReducers({ auth, site });
