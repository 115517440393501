import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
// import MaskedInput from 'react-text-mask';


const customStyles = (theme: any) => ({
  textFieldRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  textFieldInput: {
    color: '#414141',
    borderRadius: '4px 0px 0px 4px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #D5D5D5',
    fontSize: 16,
    padding: '10px 12px',
    width: '100%',
    fontWeight: 600,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  },
  textFieldInputSelected: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '3px solid ' + green[500],
    fontSize: 16,
    padding: '15px 17px',
    width: '100%'
  },
  textFieldFormLabel: {
    fontSize: 18,
  },
  textInputContainer: {
    minWidth: '100%',
    paddingBottom: 23
  }
});

interface TextInputProps {
  type: string;
  result: string | number;
  classes?: any;
  selected?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  width?: number;
  title?: string;
  maxLength?: number;
  multiline?: boolean;
  onChange(event: any): void;
}

// const TextMaskCustom = (props: any) => {
//   const { inputRef, ...other } = props;

//   return (
//     <MaskedInput
//       {...other}
//       ref={(ref: any) => {
//         inputRef(ref ? ref.inputElement : null);
//       }}
//       mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
//       placeholderChar={'\u2000'}
//       showMask
//     />
//   );
// }

class TextInput extends Component<TextInputProps> {
  render() {
    const { classes } = this.props;
    const name = this.props.placeholder || this.props.title;
    const maxLength = this.props.maxLength || 250;
    const inputProps: any = {
      readOnly: this.props.readOnly,
      disableUnderline: true,
      classes: {
        root: classes.textFieldRoot,
        input: classes.textFieldInput,
      }
    };

    // if (this.props.type === 'phone') {
    //   inputProps.inputComponent = TextMaskCustom;
    // }
    
    return (
      <div >
        <div className={classes.textInputContainer} style={(this.props.width !== undefined ? {width: this.props.width} : {})}>
          { this.props.title !== undefined &&
            <div style={{fontSize: 14, textAlign: 'left', color: '#627995'}}>{this.props.title}</div>
          }
          <TextField
            type={(this.props.type === 'phone' ? 'string' : this.props.type)}
            id={`bootstrap-input-${this.props.type}-${name}`}
            value={this.props.result}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            inputProps={{ maxLength }}
            InputProps={inputProps}
            InputLabelProps={{
              shrink: true,
              className: classes.textFieldFormLabel,
            }}
            fullWidth
            classes={classes.input}
            multiline={(this.props.multiline !== undefined && this.props.multiline ? true : false)}
            rows={(this.props.multiline !== undefined && this.props.multiline ? 4 : 1)}
            />
        </div>
      </div>
    )
  }
}

export default withStyles(customStyles)(TextInput);