import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import numeral from 'numeral';
import { CSVLink } from "react-csv";
import moment from 'moment';
import { sortBy, filter } from 'lodash';

// material
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, Table, TableHead, TableRow, TableCell, TableBody, Collapse, Box, Typography, Grid, Button, Select, Card, InputLabel } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GetAppIcon from '@material-ui/icons/GetApp';
// components
import Loading from '../../components/site/Loading';
// containers
import AppContainer from '../../containers/App';
// entities
import { Auth, UserDevice } from '../../entities/auth';
import { Vital, VitalRow } from '../../entities/vital';
// site
import { openSnackbar, closeSnackbar } from '../../store/action-creators';
// services
import AuthService from '../../services/auth';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {

        },
        filterContainer: {
            padding: 15
        },
        expandCell: {
            color: 'rgb(0, 179, 254)',
            cursor: 'pointer'
        },
        containerItem: {
            paddingTop: 40,
            paddingBottom: 40
        },
        value: {
            fontSize: 48
        },
        title: {
            fontSize: 16,
            color: '#9E9E9E'
        },
        button: {
            marginTop: 10,
            color: '#FFF'
        }
    })
);

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

interface ApiProps {
    history: any;
    auth: Auth;
    openSnackbar(message: string): void;
    closeSnackbar(): void;
}

interface RowProps {
    vital: Vital;
}

const Row = (props: RowProps) => {
    const { vital } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} style={{ color: 'rgb(0, 179, 254)' }}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{vital.id}</TableCell>
                <TableCell align="left">{vital.sessionId}</TableCell>
                <TableCell align="left">{vital.deviceId}</TableCell>
                <TableCell align="left">{vital.sensor}</TableCell>
                <TableCell align="left">{vital.type}</TableCell>
                <TableCell align="left">{vital.uom}</TableCell>
                <TableCell align="left">{moment(vital.createdDate).format('YYYY-MM-DDTHH:mm:ssZ')}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Sensor Data - {vital.data.length} total
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    {(vital.type === 'accelerometer' || vital.type === 'gyroscope') &&
                                        <TableRow>
                                            <TableCell>X</TableCell>
                                            <TableCell>Y</TableCell>
                                            <TableCell>Z</TableCell>
                                        </TableRow>
                                    }
                                    {vital.type === 'heartRate' &&
                                        <TableRow>
                                            <TableCell>vital</TableCell>
                                            <TableCell />
                                            <TableCell />
                                        </TableRow>
                                    }
                                </TableHead>
                                <TableBody>
                                    {vital.data.map((dataRow: any, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell>{vital.type === 'accelerometer' || vital.type === 'gyroscope' ? dataRow.x : dataRow}</TableCell>
                                            <TableCell>{vital.type === 'accelerometer' || vital.type === 'gyroscope' ? dataRow.y : ''}</TableCell>
                                            <TableCell>{vital.type === 'accelerometer' || vital.type === 'gyroscope' ? dataRow.z : ''}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const Reports: React.FC<ApiProps> = (props) => {

    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [vitals, setVitals] = useState<Vital[]>([]);
    const [vitalsCsv, setVitalsCsv] = useState<VitalRow[]>([]);
    const [type, setType] = useState<string>("accelerometer");
    const [device, setDevice] = useState<string>("all");
    const [userDevices, setUserDevices] = useState<UserDevice[]>([]);


    const fetchReports = async (type: string, device?: string) => {
        try {
            setLoading(true);
            const vitalRows: VitalRow[] = [];
            const vitalsRes = await axios.get(`${AuthService.getApiUrls().vital}/vitals/device/${device}?type=${type}`);
            let results = sortBy(vitalsRes.data.results, 'createdDate');
            if (device && device !== 'all') {
                results = results.filter(r => r.deviceId === device);
            }

            results.forEach((vital: Vital) => {
                vital.data.forEach((data: any, index: number) => {
                    const newVitalRow: VitalRow = {
                        id: vital.id,
                        userId: vital.userId,
                        type: vital.type,
                        uom: vital.uom,
                        deviceId: (vital.deviceId ? vital.deviceId : ''),
                        sensor: (vital.sensor ? vital.sensor : ''),
                        sessionStartDate: (vital.sessionStartDate ? moment(vital.sessionStartDate).format() : ''),
                        sessionEndDate: (vital.sessionEndDate ? moment(vital.sessionEndDate).format() : '')
                    };
                    if (vital.type === 'accelerometer' || vital.type === 'gyroscope') {
                        newVitalRow.x = data.x;
                        newVitalRow.y = data.y;
                        newVitalRow.z = data.z;
                    } else {
                        newVitalRow.v1 = data;
                    }
                    vitalRows.push(newVitalRow)
                });
            });

            setVitalsCsv(vitalRows);
            setVitals(results);
            setLoading(false);
        } catch (error) {
            console.log('error', error);
        }
    }

    const fetchUserDevices = async () => {
        try {
            const userId = AuthService.getUserId();
            const userDevicesRes = await axios.get(`${AuthService.getApiUrls().user}/user/${userId}/devices`);

            setUserDevices(userDevicesRes.data);

            if (userDevicesRes.data.length) {
                fetchReports(type, userDevicesRes.data[0].deviceId);
                setDevice(userDevicesRes.data[0].deviceId);
            }

        } catch (error) {

        }
    }

    const handleTypeChange = (newType: string) => {
        setType(newType);
        fetchReports(newType, device);
    }

    const handleDeviceChange = (newDevice: string) => {
        setDevice(newDevice);
        fetchReports(type, newDevice);
    }

    useEffect(() => {
        // load
        fetchUserDevices();
    }, []);

    return (
        <AppContainer showOption={false} private={false} maxWidth="lg">
            <Loading open={loading} />
            <div className={classes.container}>
                <Grid container>
                    <Grid item xs={4}>
                        <div className={classes.containerItem}>
                            <div className={classes.value}>{numeral(vitals.length).format('0,0')}</div>
                            <div className={classes.title}>Sessions</div>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.containerItem}>
                            <div className={classes.value}>{numeral(vitalsCsv.length).format('0,0')}</div>
                            <div className={classes.title}>Movements</div>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.containerItem}>
                            <div className={classes.value}>{numeral(1).format('0,0')}</div>
                            <div className={classes.title}>Users</div>
                        </div>
                    </Grid>
                </Grid>
                <Card variant="outlined" className={classes.filterContainer}>
                    <Grid container justify="space-between" direction="row">
                        <Grid item>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <InputLabel style={{ textAlign: 'left', fontSize: 12 }}>Sensor Type</InputLabel>
                                    <Select
                                        native
                                        value={type}
                                        onChange={(event: any) => handleTypeChange(event.target.value)}
                                        inputProps={{
                                            name: 'Sensor Type',
                                            id: 'age-native-simple',
                                        }}
                                    >
                                        <option value="accelerometer">Accelerometer</option>
                                        <option value="gyroscope">Gyroscope</option>
                                        <option value="heartRate">Heart Rate</option>
                                    </Select>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <InputLabel style={{ textAlign: 'left', fontSize: 12 }}>Device</InputLabel>
                                    <Select
                                        native
                                        value={device}
                                        onChange={(event: any) => handleDeviceChange(event.target.value)}
                                        inputProps={{
                                            name: 'Sensor Type',
                                            id: 'age-native-simple',
                                        }}
                                    >
                                        <option value="">Select Device</option>
                                        {userDevices.map((userDevice: UserDevice, index) => (
                                            <option key={userDevice.deviceId} value={userDevice.deviceId}>{userDevice.name} ({userDevice.deviceId})</option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ justifyItems: 'flex-end' }}>
                                <CSVLink
                                    data={vitalsCsv}
                                    filename={"accelerometer-export.csv"}
                                    className="export-button"
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<GetAppIcon />}
                                    >
                                        Export to CSV
                                    </Button>
                                </CSVLink>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
                <div style={{ paddingBottom: 60 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="center">Id</TableCell>
                                <TableCell align="center">SessionId</TableCell>
                                <TableCell align="center">Device</TableCell>
                                <TableCell align="center">Sensor</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Uom</TableCell>
                                <TableCell align="center">Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vitals.map((vital: Vital, index) => (
                                <Row key={vital.id} vital={vital} />
                            ))}
                            {vitals.length === 0 &&
                                <TableRow>
                                    <TableCell colSpan={7} style={{textAlign: 'center'}}>No vitals to display</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </div>
            </div>
        </AppContainer>
    );
};

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth.auth
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        openSnackbar,
        closeSnackbar
    }, dispatch);
};

export default withRouter<any, any>(
    connect(mapStateToProps, mapDispatchToProps)(Reports)
);
