import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { closeSnackbar, openSnackbar } from '../store/action-creators';
import { withRouter } from 'react-router-dom';
// material
import Container from '@material-ui/core/Container';
import Header from '../components/site/Header';
import Footer from '../components/site/Footer';
import { Auth } from '../entities/auth';
import { FormatColorReset } from '@material-ui/icons';

const styles: any = {
  container: {
    width: '100%',
    minHeight: '100%',
  }
}

interface AppContainerProps {
  history: any;
  children: any;
  auth: Auth;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

class HomeContainer extends Component<AppContainerProps, {}> {

  render() {
    const maxWidth = this.props.maxWidth || false;
    return (
      <div style={styles.container}>
        <Header showLogo={false} />
        <Container maxWidth={maxWidth}>
          <div style={styles.bodyContainer}>
            {this.props.children}
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    site: state.site.site
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ closeSnackbar, openSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(HomeContainer));