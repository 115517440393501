import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Auth from '@aws-amplify/auth';

// app
import { Auth as AuthEntity } from '../../../entities/auth';
import { Message, MessageTypes } from '../../../entities/site';
import { setSignup, setMessage, clearMessage } from '../../../store/action-creators';
import { colors } from '../../../config/styles';
// containers
import AuthContainer from '../containers/Auth';
import RowContainer from '../containers/Row';
// components
import TextInput from '../../../components/inputs/TextInput';
import ActionButton from '../../../components/buttons/ActionButton';

const styles: any = {
  link: {
    color: colors.blue,
    cursor: 'pointer'
  },
  footer: {
    textAlign: 'center',
    fontSize: 12
  }
}

// const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
// const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

const lowerRegex = new RegExp("^(?=.*[a-z])");
const upperRegex = new RegExp("^(?=.*[A-Z])");
const numberRegex = new RegExp("^(?=.*[0-9])");
const lengthRegex = new RegExp("^(?=.{6,})");

interface SignupProps {
  open: any;
  auth: AuthEntity;
  onClose(route?: string): void
  setSignup(username: string, email: string, name: string, phone: string, isReset: boolean, password: string): void;
  setMessage(message: Message): void;
  clearMessage(): void;
}

interface SignupState {
  name: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirm: string;
  message: Message;
  disabled: boolean;
}

class Signup extends Component<SignupProps, SignupState> {

  constructor(props: SignupProps) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      email: '',
      password: '',
      passwordConfirm: '',
      message: {
        message: '',
        messageType: MessageTypes.notification
      },
      disabled: false
    }
  }

  componentDidMount = () => {
    // mounted
  }

  onNameChange = (event: any) => {
    this.setState({ name: event.target.value });
  }

  onEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  }

  onPhoneChange = (event: any) => {
    this.setState({ phone: event.target.value });
  }

  onPasswordChange = (event: any) => {
    this.setState({ password: event.target.value });
  }

  onPasswordConfirmChange = (event: any) => {
    this.setState({ passwordConfirm: event.target.value });
  }

  handleSignup = () => {
    // sign up
    try {
      if (!this.checkForm()) {
        return;
      }

      this.setState({
        disabled: true
      });

      const data: any = {
        username: this.state.email,
        password: this.state.password,
        attributes: {
          name: this.state.name
        }
      };
      // if (this.state.phone) {
      //   data.attributes.phone = `+1${this.state.phone}`;
      // }

      Auth.signUp(data)
        .then((res: any) => {

          // set signup 
          this.props.setSignup(res.user.username, this.state.email, this.state.name, this.state.phone, false, this.state.password);

          this.setState({ disabled: false });

          // load confirm code page
          this.props.onClose('confirm');

        })
        .catch((err) => {
          this.setState({ disabled: true });
          this.setState({ message: { message: 'Error logging in', messageType: MessageTypes.error } });
        });
    } catch (error) {
      this.setState({ disabled: true });
      this.setState({ message: { message: 'Error logging in', messageType: MessageTypes.error } });
    }
  }

  handleLogin = () => {
    this.props.onClose('login');
  }

  handleConfirmCode = () => {
    this.props.onClose('confirm');
  }

  checkForm = () => {
    let nameError = '';
    let pError = '';
    let emailError = '';
    if (!this.state.email) {
      emailError = 'Email required';
    }
    if (!this.state.name) {
      nameError = 'Name required';
    }
    if (!this.state.password) {
      pError = 'Password required';
    }
    if (this.state.password !== this.state.passwordConfirm) {
      pError = 'Passwords do not match';
    }
    if (
      !lowerRegex.test(this.state.password) ||
      !upperRegex.test(this.state.password) ||
      !numberRegex.test(this.state.password) ||
      !lengthRegex.test(this.state.password)
    ) {
      pError = 'Password must be at least 6 chars, 1 upper and 1 number';
    }
    // this.props.setMessage({message: 'Error logging in', messageType: MessageTypes.error});
    this.setState({ message: { message: pError, messageType: MessageTypes.error } });
    if (!emailError && !nameError && !pError) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <AuthContainer
        open={this.props.open}
        title="Signup"
        onClose={this.props.onClose}
        message={this.state.message}
      >
        <RowContainer>
          <TextInput
            title="Name"
            type="string"
            placeholder=""
            result={this.state.name}
            onChange={this.onNameChange}
          />
        </RowContainer>
        <RowContainer>
          <TextInput
            title="Phone - (Optional)"
            type="phone"
            placeholder=""
            result={this.state.phone}
            onChange={this.onPhoneChange}
          />
        </RowContainer>
        <RowContainer>
          <TextInput
            title="Email"
            type="string"
            placeholder=""
            result={this.state.email}
            onChange={this.onEmailChange}
          />
        </RowContainer>
        <RowContainer>
          <TextInput
            title="Password"
            type="password"
            placeholder=""
            result={this.state.password}
            onChange={this.onPasswordChange}
          />
        </RowContainer>
        <RowContainer>
          <TextInput
            title="Confirm Password"
            type="password"
            placeholder=""
            result={this.state.passwordConfirm}
            onChange={this.onPasswordConfirmChange}
          />
        </RowContainer>
        <RowContainer>
          <ActionButton name="Signup" onClick={this.handleSignup} fullWidth size="sm" disabled={this.state.disabled} />
        </RowContainer>
        <RowContainer>
          <div style={styles.footer}>Already signed up? <span style={styles.link} onClick={this.handleLogin}>Login</span></div>
          <div style={styles.footer}>Have confirm code? <span style={styles.link} onClick={this.handleConfirmCode}>Verify</span></div>
        </RowContainer>
      </AuthContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ setSignup, setMessage, clearMessage }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(Signup));
