import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Amplify from '@aws-amplify/core';
import { isEmpty } from 'lodash';
// styles
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.css';
// store
import store from './store/';
// site
import routes from './config/routes';
import ProtectedRoute from './components/auth/ProtectedRoute';
import NoFound from './pages/home/NotFound';
import AuthService from './services/auth';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#00ADCB' },
        secondary: { main: '#D0D0D0' },
    },
});

Amplify.configure({
    Auth: {
        identityPoolId: '',
        region: 'us-west-2',
        userPoolId: 'us-west-2_09srrAJJS',
        userPoolWebClientId: 'fqnbligp885jmrvfh51h5it1'
    }
});
Amplify.Logger.LOG_LEVEL = 'DEBUG';

axios.interceptors.request.use(async (config) => {

    const reduxStore: any = store.getState();
    const currentTime = moment().unix();
    const auth = (!isEmpty(reduxStore.auth.auth) ? reduxStore.auth.auth : null);
    const exp = (auth && auth.payload ? auth.payload.exp : null);
  
    let token = null;
    if (!isEmpty(auth) && ((exp - currentTime) < 0)) {
  
      console.log('refreshing token...')
  
      const newSession = await AuthService.refreshToken()
      const accessToken = newSession.getAccessToken();
      token = accessToken.getJwtToken();
      auth.token = token;
      auth.payload = accessToken.payload;
  
      store.dispatch({
        type: 'ADD_AUTH',
        payload: auth
      });
  
    }
    if (!isEmpty(auth) && auth.token) {
      token = auth.token;
    }
  
    if (token) {
      config.headers = { 'Authorization': token };
    }
  
    return config;
  
  }, (error: any) => {
    // Do something with request error
    return Promise.reject(error);
  });
  
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    console.log('network error', error);
    // window.location.href = 'http://localhost:3000/auth/logout';
    // Do something with request error
    return Promise.reject(error.response.data);
  });
  

class App extends React.Component {

    handleStoreCallback = () => {
        // set defaults in store
    };

    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistStore(store, null, this.handleStoreCallback)}>
                    <MuiThemeProvider theme={theme}>
                        <Router>
                            <div className="App">
                                <main className="Main">
                                    <Switch>
                                        {routes.private.map((route: any) =>
                                            <ProtectedRoute
                                                key={route.path}
                                                path={route.path}
                                                exact={route.exact}
                                                roles={route.roles}
                                                component={route.component}
                                            />
                                        )}
                                        {routes.public.map((route: any) =>
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                exact={route.exact}
                                                component={route.component} />
                                        )}
                                        <Route component={NoFound} />
                                    </Switch>
                                </main>
                            </div>
                        </Router>
                    </MuiThemeProvider>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
