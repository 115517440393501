export interface Site {
  snackbar: boolean;
  snackbarMsg: string;
}

export interface Message {
  message: string;
  messageType: MessageTypes;
}

export enum MessageTypes {
  error = "error",
  notification = "notification",
  warning = "warning"
}

export const emptyMessage = {
  message: '',
  messageType: MessageTypes.notification
}

export interface Log {
  id: string;
  body: string;
  createdDate: number;
}