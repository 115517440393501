import actions from "./actions";
import { Auth, Signup } from "../entities/auth";
import { Message, MessageTypes } from "../entities/site";

// AUTH

export function setSignup(
  username: string,
  email: string,
  name: string,
  phone: string,
  isReset: boolean,
  password?: string,
  user?: any
): { type: actions.SET_SIGNUP; payload: Signup } {
  return {
    type: actions.SET_SIGNUP,
    payload: {
      username,
      email,
      name,
      phone,
      isReset,
      password,
      user,
    },
  };
}

export function clearSignup(): { type: actions.CLEAR_SIGNUP; payload: Signup } {
  return {
    type: actions.CLEAR_SIGNUP,
    payload: {} as Signup,
  };
}

export function addAuth(auth: Auth): { type: actions.ADD_AUTH; payload: Auth } {
  return {
    type: actions.ADD_AUTH,
    payload: auth,
  };
}

export function clearAuth(): { type: actions.CLEAR_AUTH; payload: null } {
  return {
    type: actions.CLEAR_AUTH,
    payload: null,
  };
}

export function deleteAuth(): { type: actions.DELETE_AUTH; payload: null } {
  return {
    type: actions.DELETE_AUTH,
    payload: null,
  };
}

// SITE

export function openSnackbar(
  msg: string
): { type: actions.OPEN_SNACKBAR; payload: string } {
  return {
    type: actions.OPEN_SNACKBAR,
    payload: msg,
  };
}

export function closeSnackbar(): { type: actions.CLOSE_SNACKBAR; payload: boolean } {
  return {
    type: actions.CLOSE_SNACKBAR,
    payload: false,
  };
}

export function setMessage(
  message: Message
): { type: actions.SET_MESSAGE; payload: Message } {
  return {
    type: actions.SET_MESSAGE,
    payload: message,
  };
}

export function clearMessage(): {
  type: actions.CLEAR_MESSAGE;
  payload: Message;
} {
  return {
    type: actions.CLEAR_MESSAGE,
    payload: {
      message: "",
      messageType: MessageTypes.notification,
    },
  };
}